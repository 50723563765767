import * as React from "react"
import { Helmet } from "react-helmet"
import tw, { GlobalStyles } from "twin.macro"
import { Global } from "@emotion/react"
import Header from "../components/header"
import Footer from "../components/footer"
import TableOfContents from "../components/toc"
import Sidebar from "./sidebar"
import global from "../assets/theme/global"
import MobileSidebar from "./mobile-sidebar"
import { useScrollRestoration } from "gatsby"

function Layout({ children, sideBarItems, headings }) {
  const sidbarScrollRestoration = useScrollRestoration(`page-component-sidebar`)


  return (
    <React.Fragment>
      <GlobalStyles />
      <Global styles={global} />
      <Helmet meta={[{ name: "theme-color", content: "green" }]} />
      <Header />
      <MobileSidebar items={sideBarItems} />
      <main
        css={tw`font-sans antialiased flex gap-4 font-sans bg-gray-100 text-gray-900 justify-between dark:(bg-gray-900  text-white) h-[calc(100vh - 4rem)]`}
      >
        <aside
          css={tw`hidden lg:block bg-gray-200 dark:bg-gray-900 p-6 overscroll-auto overflow-y-auto shadow-inner w-64`}
          {...sidbarScrollRestoration}
        >
          <Sidebar items={sideBarItems} />
        </aside>
        <div css={tw`w-full lg:w-2/3 overflow-y-auto py-6 !px-4 lg:!px-2`}>
          {children}
        </div>
        <aside
          css={tw`hidden lg:block p-4 shadow-inner overscroll-auto overflow-y-auto w-60`}
        >
          <TableOfContents items={headings} />
        </aside>
      </main>
      <Footer />
    </React.Fragment>
  )
}

export default Layout
