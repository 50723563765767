import PropTypes from "prop-types"


const ItemPropTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string
}
export const SidebarItemPropTypes = {
  ...ItemPropTypes,
  items: PropTypes.arrayOf( PropTypes.shape( ItemPropTypes ) )
}


export const SidebarPropTypes = {
  isOpen: PropTypes.bool,
  location: PropTypes.object,
  items: PropTypes.arrayOf( PropTypes.shape( SidebarItemPropTypes ) )
}
