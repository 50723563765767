import { css } from "@emotion/react"
import tw from "twin.macro"
import admonitions from "./admonition"

export default css`
  ${admonitions}
  pre[class*="language-"] {
    ${tw`relative !p-6`}
  }

  pre[class*="language-"] code {
    ${tw`font-mono`}
  }

  pre[class*="language-"]::before {
    background: #d9d7e0;
    border-radius: 0 0 4px 4px;
    color: #232129;
    font-size: 12px;
    font-family: inherit;
    letter-spacing: 0.075em;
    line-height: 1;
    padding: 0.25rem 0.5rem;
    position: absolute;
    left: 1rem;
    text-align: right;
    text-transform: uppercase;
    top: 0;
  }

  pre[class~="language-js"]::before,
  pre[class~="language-javascript"]::before {
    content: "js";
    background: #f7df1e;
  }

  pre[class~="language-jsx"]::before {
    content: "jsx";
    background: #61dafb;
  }

  pre[class~="language-typescript"]::before,
  pre[class~="language-ts"]::before {
    content: "ts";
    background: #294e80;
    color: #fff;
  }

  pre[class~="language-tsx"]::before {
    content: "tsx";
    background: #294e80;
    color: #fff;
  }

  pre[class~="language-graphql"]::before {
    content: "GraphQL";
    background: #e10098;
    color: #fff;
  }

  pre[class~="language-html"]::before {
    content: "html";
    background: #005a9c;
    color: #fff;
  }

  pre[class~="language-css"]::before {
    content: "css";
    background: #ff9800;
    color: #fff;
  }

  pre[class~="language-mdx"]::before {
    content: "mdx";
    background: #f9ac00;
    color: #fff;
  }

  pre[class~="language-shell"]::before {
    content: "shell";
  }

  pre[class~="language-sh"]::before {
    content: "sh";
  }

  pre[class~="language-bash"]::before {
    content: "bash";
  }

  pre[class~="language-yaml"]::before,
  pre[class~="language-yml"]::before {
    content: "yaml";
    background: #ffa8df;
  }

  pre[class~="language-markdown"]::before {
    content: "md";
  }

  pre[class~="language-json"]::before,
  pre[class~="language-json5"]::before {
    content: "json";
    background: linen;
  }

  pre[class~="language-diff"]::before {
    content: "diff";
    background: #e6ffed;
  }

  pre[class~="language-text"]::before {
    content: "text";
    background: #fff;
  }

  pre[class~="language-http"]::before {
    content: "http";
    background: #cfd8dc;
  }

  pre[class~="language-handlebars"]::before {
    content: "handlebars";
    background: #f0772b;
  }
`
