import PropTypes from "prop-types"
import * as React from "react"
import tw from "twin.macro"
import { Disclosure } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"

const BurgerMenu = ({ isOpen }) => {
  return (
    <Disclosure.Button
      type="button"
      css={tw`inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white`}
      aria-controls="mobile-menu"
      aria-expanded="false"
    >
      <span css={tw`sr-only`}>Open main menu</span>
      {isOpen ? (
        <XIcon css={tw`block h-6 w-6`} aria-hidden="true" />
      ) : (
        <MenuIcon css={tw`block h-6 w-6`} aria-hidden="true" />
      )}
    </Disclosure.Button>
  )
}

BurgerMenu.propTypes = {
  isOpen: PropTypes.bool,
}

export default BurgerMenu
