import { css } from "@emotion/css"
import tw from "twin.macro"
import { Disclosure } from "@headlessui/react"
import { ChevronRightIcon } from "@heroicons/react/outline"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import startsWith from "lodash-es/startsWith"
import find from "lodash-es/find"

import * as React from "react"

const itemClassName = css(
  tw`text-gray-900 dark:text-white hover:(bg-gray-700 text-white) px-3 py-2 rounded-md text-sm font-medium max-w-full break-words `
)

const activeClassName = css`
  ${tw`bg-gray-400`}
`
const Item = ({ label, link, items, ...props }) => {
  const isNested = Array.isArray(items)
  const { pathname } = useLocation()

  if (isNested) {
    const defaultOpen =
      find(items, item => startsWith(item.link, pathname)) !== undefined

    return (
      <Disclosure defaultOpen={defaultOpen} as="div">
        {({ open }) => (
          <>
            <Disclosure.Button
              className={itemClassName}
              css={tw`w-full flex gap-2 justify-between items-center cursor-pointer text-left`}
            >
              <span>{label}</span>
              <ChevronRightIcon
                css={[tw`w-4 h-4`, open ? tw`transform rotate-90` : null]}
              />
            </Disclosure.Button>
            <Disclosure.Panel as="div" css={tw`flex flex-col gap-3 py-2 pl-2`}>
              {items?.map((item, index) => (
                <Item key={index} {...item} />
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    )
  }

  return (
    <Link
      {...props}
      activeClassName={activeClassName}
      className={itemClassName}
      to={link}
    >
      {label}
    </Link>
  )
}

export default Item
