import * as React from "react"
import { SidebarPropTypes } from "./types"
import tw from "twin.macro"
import Item from "./item"

function Sidebar({ items = [] }) {
  return (
    <nav aria-label="Navigation Menu">
      <div css={tw`flex flex-col gap-2`}>
        {items.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </div>
    </nav>
  )
}

Sidebar.defaultProps = {
  isOpen: true,
  items: [],
}

Sidebar.propTypes = {
  ...SidebarPropTypes,
}

export default Sidebar
