import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import tw from "twin.macro"
import { css } from "@emotion/css"
import Search from "../search"
import { Disclosure } from "@headlessui/react"
import { HomeIcon } from "@heroicons/react/outline"

import BurgerMenu from "./burger-button"
import ColorModeButton from "./color-mode-button"

const activeClassName = css`
  ${tw`bg-gray-900 `}
`

const activeHomeClassName = css`
  ${tw`ring-1 ring-white bg-gray-700 `}
`

const linkCss = tw`text-gray-300 hover:(bg-gray-700 text-white) px-3 py-2 rounded-md text-sm font-medium`

const whatLocation = props => {
  const currentLocation = props.location.pathname

  if (props.type === "html-css") {
    const types = props.type.split("-").join("|")
    const isMatch = currentLocation.match(new RegExp(types))
    if (isMatch) {
      return { className: css([linkCss, activeClassName]) }
    }
  }
  if (currentLocation.includes(props.type)) {
    return { className: css([linkCss, activeClassName]) }
  }
}

const HomeLink = () => {
  return (
    <Link
      to="/"
      activeClassName={activeHomeClassName}
      css={tw`flex items-center justify-center bg-gray-700 bg-opacity-60 rounded-full text-gray-300 w-8 h-8 ring-1 ring-gray-600 hover:(bg-gray-700 text-white)`}
    >
      <HomeIcon css={tw`w-5 h-5 stroke-current`} />
    </Link>
  )
}

const Header = () => {
  const {
    allHeaderNav: { nodes },
  } = useStaticQuery(
    graphql`
      query HeaderNavQuery {
        allHeaderNav(filter: { type: { ne: "root" } }) {
          nodes {
            title
            id
            slug
            type
          }
        }
      }
    `
  )

  const main = nodes.map(node => (
    <Link
      getProps={props => whatLocation({ type: node.type, ...props })}
      css={linkCss}
      activeClassName={activeClassName}
      key={node.id}
      to={node.slug}
    >
      {node.title}
    </Link>
  ))

  return (
    <Disclosure
      as="header"
      css={tw`bg-gray-800 shadow-inner backdrop-filter backdrop-blur`}
    >
      {({ open }) => (
        <React.Fragment>
          <div css={tw`max-w-screen-2xl mx-auto px-2 sm:px-6 lg:px-8`}>
            <div css={tw`relative flex items-center justify-between h-16`}>
              <div css={tw`flex items-center sm:hidden`}>
                <BurgerMenu isOpen={open} />
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div css={tw`hidden sm:block sm:ml-6`}>
                  <div css={tw`flex space-x-4`}>
                    <HomeLink />
                    {main}
                  </div>
                </div>
              </div>
              <div css={tw`hidden items-center gap-2 w-full md:w-96`}>
                <Search />
              </div>
              <ColorModeButton />
            </div>
          </div>
          <Disclosure.Panel css={tw`sm:hidden`}>
            <div css={tw`flex flex-col px-2 pt-2 pb-3 gap-y-1`}>
              <Link activeClassName={activeClassName} css={linkCss} to="/">
                Главная
              </Link>
              {nodes.map(node => (
                <Link
                  activeClassName={activeClassName}
                  css={linkCss}
                  key={node.id}
                  to={node.slug}
                >
                  {node.title}
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </React.Fragment>
      )}
    </Disclosure>
  )
}

export default Header
