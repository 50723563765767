import * as React from "react"
import tw from "twin.macro"
import { LightBulbIcon, MoonIcon } from "@heroicons/react/outline"
import styled from "@emotion/styled"

const themeStorageKey = "dark-mode"

const Button = styled.button`
  ${tw`transition-all w-9 h-9 rounded-full text-sm text-center flex items-center justify-center`}
  ${tw`ring-1 ring-gray-600  focus:outline-none focus:ring-0`}
  ${tw`hover:(bg-opacity-70)`}
`

const getTheme = () => {
  if (typeof window === "undefined") return "dark"
  return localStorage.getItem(themeStorageKey) || "dark"
}

const setLightMode = () => {
  try {
    localStorage.setItem(themeStorageKey, "light")
    document.documentElement.classList.remove("dark")
  } catch (err) {
    console.error(err)
  }
}

const setDarkMode = () => {
  try {
    localStorage.setItem(themeStorageKey, "dark")
    document.documentElement.classList.add("dark")
  } catch (err) {
    console.error(err)
  }
}

const ColorModeButton = () => {
  const [isDark, toggleDark] = React.useState(getTheme() === "dark")
  const changeTheme = () => {
    toggleDark(!isDark)
    if (isDark) {
      setLightMode()
    } else {
      setDarkMode()
    }
  }

  return (
    <Button
      css={[isDark ? tw`bg-blue-700` : tw`bg-yellow-300`]}
      onClick={changeTheme}
    >
      {isDark ? (
        <LightBulbIcon css={tw`text-yellow-300 w-6 h-6`} />
      ) : (
        <MoonIcon css={tw`text-indigo-500 w-6 h-6 `} />
      )}
    </Button>
  )
}

export default ColorModeButton
