import { graphql } from "gatsby"
import React from "react"
import Doc from "../components/doc"

const normalizeRootItems = allSideBarNav => {
  return (
    allSideBarNav?.edges?.map(({ node }) => ({
      link: node.link,
      label: node.title,
      items: null,
      id: node.id,
    })) ?? []
  )
}

const DocTemplate = ({ data, location }) => {
  const { type } = data.doc
  const sideBarItems =
    type === "root"
      ? normalizeRootItems(data.allSideBarNav)
      : data?.sideBarNav?.items ?? []

  return <Doc data={data} sideBarItems={sideBarItems} location={location} />
}

export const query = graphql`
  query ($id: String!, $type: String!) {
    doc: doc(id: { eq: $id }) {
      id
      slug
      title
      description
      body
      type
      headings {
        depth
        value
      }
    }

    allSideBarNav(filter: { type: { eq: $type } }) {
      edges {
        node {
          id
          type
          title
          link
          items {
            label
            link
            items {
              label
              link
            }
          }
        }
      }
    }

    sideBarNav(type: { eq: $type }) {
      items {
        label
        link
        items {
          label
          link
        }
      }
    }
  }
`

export default DocTemplate
