import * as React from "react"
import { Link } from "gatsby"
import filter from "lodash-es/filter"
import inRange from "lodash-es/inRange"
import kebabCase from "lodash-es/kebabCase"
import tw from "twin.macro"
import createAnchor from "../../utils/create-anchor"

const TableOfContents = ({ items }) => {
  const heading = React.useMemo(() => {
    return filter(items, item => inRange(item.depth, 2, 4))
  }, [])

  if (heading.length === 0) {
    return null
  }

  return (
    <React.Fragment>
      <h1 css={tw`text-2xl font-serif mb-4`}>Оглавление</h1>
      <nav>
        <ul css={tw`flex flex-col gap-2`}>
          {heading.map((heading, index) => (
            <li
              key={index}
              css={[
                tw`py-3 px-3 text-sm hover:(bg-gray-400 bg-opacity-40 rounded-md)`,
                heading.depth > 2 ? tw`pl-6` : null,
              ]}
            >
              <Link data-scroll={true} to={`#${createAnchor(heading.value)}`}>
                {heading.value}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </React.Fragment>
  )
}

export default TableOfContents
