import tw from "twin.macro"
import * as React from "react"
import { MenuAlt2Icon, XIcon } from "@heroicons/react/outline"
import { Disclosure } from "@headlessui/react"
import Item from "../sidebar/item"

const menuBtnCss = tw`inline-flex text-gray-400 items-center justify-center p-2 rounded-md  hover:(text-white bg-gray-600) focus:(outline-none ring-2 ring-inset ring-white)`
const menuIconCss = tw`h-6 w-6`

const MobileSidebar = ({ items }) => {
  const hasItems = items.length > 0
  return (
    <Disclosure as={React.Fragment}>
      <div
        css={[
          tw`sm:hidden bg-gray-700 shadow-inner flex lg:hidden items-center px-2 sm:px-6 lg:px-8 py-2`,
          hasItems ? tw`block` : tw`hidden`,
        ]}
      >
        <Disclosure.Button css={menuBtnCss}>
          <MenuAlt2Icon css={menuIconCss} />
        </Disclosure.Button>
      </div>

      <Disclosure.Panel
        as="nav"
        css={tw`fixed top-0 z-50 w-full h-full bg-gray-300 dark:bg-gray-600 p-6`}
      >
        <div css={tw`py-4 w-full flex`}>
          <Disclosure.Button
            css={[menuBtnCss, tw`ml-auto text-gray-800 dark:text-gray-400`]}
          >
            <XIcon css={menuIconCss} />
          </Disclosure.Button>
        </div>

        <div css={tw`flex flex-col gap-2`}>
          {items.map((item, index) => (
            <Item key={index} {...item} />
          ))}
        </div>
      </Disclosure.Panel>
    </Disclosure>
  )
}

export default MobileSidebar
