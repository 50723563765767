import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import get from "lodash-es/get"
import tw from "twin.macro"
import { MDXRenderer } from "gatsby-plugin-mdx"
import styled from "@emotion/styled"

const Container = styled.div`
  ${tw`prose prose-green lg:prose-xl mx-auto lg:max-w-full`}
  h1, h2, h3, h4, h5, h6 {
    ${tw`dark:(text-gray-200)`}
  }

  li > * {
    ${tw`!my-0`}
  }

  strong{
    ${tw`dark:text-gray-200`}
  }
  p,
  td,
  li {
    ${tw`dark:(text-gray-400)`}
  }

  .inline-code {
    ${tw`dark:(text-indigo-50)`}
  }
`

function Doc({ data: { doc }, sideBarItems, location }) {
  const headingTitle = get(doc, ["headings", "0", "value"], "")
  const title = (doc && doc.slug === "/" ? null : doc.title || headingTitle) ?? "College"
  const description = doc.description || doc.excerpt

  const { headings } = doc


  return (
    <Layout headings={headings} sideBarItems={sideBarItems} location={location}>
      <Seo title={title} description={description} lang="ru" />
      <Container>
        <MDXRenderer>{doc.body}</MDXRenderer>
      </Container>
    </Layout>
  )
}

export default Doc
