import * as React from "react"
import tw from "twin.macro"
import { SearchIcon } from "@heroicons/react/outline"
import styled from "@emotion/styled"

const Input = styled.input`
  ${tw`focus:outline-none`}
`

const Search = () => {
  return (
    <div
      css={tw`bg-white h-10 flex items-center px-2 gap-2 rounded-2xl ring-2 ring-gray-600 w-full overflow-hidden`}
    >
      <SearchIcon css={tw`h-6 w-6`} />
      <Input type="search" />
    </div>
  )
}

export default Search
