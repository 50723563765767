import * as React from "react"
import tw from "twin.macro"

const Footer = () => {
  return (
    <footer css={tw`hidden bg-red-50 p-6 text-white shadow-inner h-4`}>
      Hello
    </footer>
  )
}

export default Footer
