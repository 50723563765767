import { css } from "@emotion/react"
import tw from "twin.macro"

export default css`
  .admonition {
    ${tw`px-6 py-4 rounded-md border-l-8`}
    & + & {
      ${tw`mt-8`}
    }
  }

  .admonition h5 {
    ${tw`mt-0 mb-2 uppercase font-semibold tracking-wide`};
  }

  .admonition-icon {
    ${tw`inline-block align-middle mr-1`}
    ${tw`hidden`}
  }

  .admonition-icon svg {
    ${tw`inline-block h-5 w-5 stroke-0 mb-1`}
  }

  .admonition-content > :last-child {
    ${tw`mb-0`}
  }

  .admonition-content {
    p,
    strong {
      ${tw`!text-white`}
    }
  }

  .admonition-note {
    ${tw`bg-indigo-400 text-white border-indigo-900`}
    a {
      ${tw`!text-blue-500`}
    }
  }

  .admonition-danger {
    ${tw`bg-orange-400 bg-opacity-60 text-white border-orange-700`}
    a {
      ${tw`!text-orange-700`}
    }
  }

  .admonition-important {
    ${tw`bg-sky-500 dark:bg-sky-600 text-white border-sky-800`}
    a {
      ${tw`!text-sky-500`}
    }
  }

  .admonition-statement {
    ${tw`bg-violet-300  border-violet-900`}
    a {
      ${tw`!text-violet-500`}
    }
  }
`
